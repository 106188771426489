import React, { useState } from "react";
import logo_s from "../Images/logo_s.jpg";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AiOutlineDashboard } from "react-icons/ai";
import { MdProductionQuantityLimits } from "react-icons/md";
import { BsFillCalculatorFill } from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { HiPencilSquare } from "react-icons/hi2";
import { RiFileList2Line } from "react-icons/ri";
import { BiCategoryAlt } from "react-icons/bi";
import { RiFileListLine } from "react-icons/ri";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { BsBagCheck } from "react-icons/bs";
import { MdOutlineListAlt } from "react-icons/md";
import { BsClipboardData } from "react-icons/bs";
import powerr from "../Images/powerr.png";
import { RiFileAddLine } from "react-icons/ri";
import { RiFileList3Line } from "react-icons/ri";
import { IoImagesOutline } from "react-icons/io5";
import { GrDocumentImage } from "react-icons/gr";

const { Header, Sider, Content } = Layout;
const MainLayout = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const currentRoute = location.pathname;
  console.log(43, currentRoute);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    navigate("/");
  };
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-white fs-5 text-center py-3 mb-0">
            <img className="flooring-logo img-fluid" src={logo_s} />
          </h2>
        </div>
        <Menu
          mode="inline"
          style={{ backgroundColor: "#f7f6ed" }}
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key == "signout") {
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "",
              icon: <AiOutlineDashboard className="fs-4" />,
              label: "Dashboard",
            },
            {
              key: "Products",
              icon: <MdProductionQuantityLimits className="fs-4" />,
              label: "Products",
            },
            {
              key: "ProductList",
              icon: <RiFileListLine className="fs-4" />,
              label: "Product List",
            },
            {
              key: "Order",
              icon: <HiPencilSquare className="fs-4" />,
              label: "Product Order",
            },
            {
              key: "category",
              icon: <BiCategoryAlt className="fs-4" />,
              label: "Category",
            },
            {
              key: "categoryList",
              icon: <RiFileList2Line className="fs-4" />,
              label: "Category List",
            },
            {
              key: "brand",
              icon: <BsBagCheck className="fs-4" />,
              label: "Brand",
            },
            {
              key: "BrandList",
              icon: <MdOutlineListAlt className="fs-4" />,
              label: "Brand List",
            },
            {
              key: "costEstimation",
              icon: <BsFillCalculatorFill className="fs-4" />,
              label: "Cost Estimation",
            },
            {
              key: "CostEstimationList",
              icon: <BsClipboardData className="fs-4" />,
              label: "Cost Estimation List",
            },
            {
              key: "wherehouse",
              icon: <RiFileAddLine className="fs-4" />,
              label: "Add Werehouse",
            },
            {
              key: "wherehouseList",
              icon: <RiFileList3Line className="fs-4" />,
              label: "Werehouse List",
            },
            {
              key: "banner",
              icon: <GrDocumentImage className="fs-4" />,
              label: "Banner",
            },
            {
              key: "bannerList",
              icon: <IoImagesOutline className="fs-4" />,
              label: "Banner List",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-content-between ps-1 pe-5"
          style={{
            // padding: 0,
            background: "#e67929",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <div className="d-flex gap-4 align-items-center">
            <div className="d-flex gap-3 align-items-center dropdown">
              <div
                role="button"
                className=""
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  color: "white",
                  fontSize: "1.2rem",
                  textDecoration: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleLogout}
                onMouseOver={(e) =>
                  (e.currentTarget.style.textDecoration = "underline")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.textDecoration = "none")
                }
              >
                {/* Log out &nbsp; */}
                <span className="desktop-view">Log out &nbsp;</span>
                <img
                  style={{ height: "2rem", cursor: "pointer" }}
                  src={powerr}
                  alt="Power Icon"
                ></img>
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                  >
                    View Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/"
                  >
                    Signout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 10px",
            padding: `24px 10px`,
            minHeight: 280,
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
