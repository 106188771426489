import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const MainCatEdit = () => {
  const { id } = useParams();
  console.log(id);
  const [catData, setcatData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState({
    name: "",
    categoryImage: "",
    parentId: "",
    parentName: "",
    superCatName: "",
    Type: "",
  });

  useEffect(() => {
    console.log(18, data);
    axios
      .get(`https://allinonetables.com/api/getcategory/get/${id}`)
      .then((res) => {
        const response = res.data;
        data.name = response[0].name;
        data.parentName = response[0].parentName;
        data.parentId = response[0].parentId;
        console.log(27, response);
        setcatData({
          name: response.name,
          parentName: response.parentName,
          parentId: response.parentId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id,refresh]);

  const SaveCAt = (e) => {
    e.preventDefault();
    console.log(61, data);
    axios
      .post("https://allinonetables.com/api/category/createTemp", data)
      .then((res) => {
        const response = res.data.categories;
        toast.success("Added Category !");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <h3 className="mb-4 title ">Edit Main Category</h3>

      <form
        class="row g-3 pt-3 shadow p-3 mt-4"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        <div class="col-md-7">
          <label for="inputEmail4" class="form-label txt-input">
            Main Category <span className="text-danger"></span>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Enter main category.."
            value={data.name}
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
                Type: "Super_Cat",
              });
            }}
          />
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Upload File <span className="text-danger"></span>
          </label>
          <input
            class="form-control"
            type="file"
            id="formFile"
            onChange={(e) => {
              handleImageChange(e);
            }}
          />
          {selectedImage && (
            <div>
              <img
                src={selectedImage}
                className="m-1"
                alt="Selected"
                style={{ maxWidth: "100%", maxHeight: "33px" }}
              />
            </div>
          )}
        </div>

        <div class="col-md-12 " style={{ marginTop: 20 }}>
          <button
            type="submit"
            class="btn btn-primary shadow border-0 p-2 fw-bold"
            style={{ backgroundColor: "#e67929", width: 100 }}
            onClick={(e) => {
              SaveCAt(e);
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default MainCatEdit;
