import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function Banner() {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const { id } = useParams();
  console.log(13, id);
  const [data, setData] = useState({
    subText: "",
    mainText: "",
    image: "",
  });
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://allinonetables.com/api/banner/get/${id}`)
      .then((res) => {
        const response = res.data;
        data.mainText = response[0].mainText;
        data.subText = response[0].subText;
        console.log(23, response);
        setBannerData({
          mainText: response.mainText,
          subText: response.subText,
        })
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = (id) => {
    const formData = new FormData();
    formData.append("image", file);
    axios
      .put(`https://allinonetables.com/api/banner/files/${id}`, formData)
      .then((res) => {toast.success("img uploaded")
      navigate(`/admin/BannerList`)
    })
      .catch((err) => {
        console.log(err);
      });
  };

  const SaveData = (e) => {
    e.preventDefault();
    console.log(data)
    console.log(file)
    axios
      .post("https://allinonetables.com/api/banner/create", data)
      .then((res) => {
        const response = res.data.data;
        console.log(54,response)
        const id = response._id;
        handleUpload(id);
        toast.success("Successfully Added !");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateFormData = (e) => {
    e.preventDefault();
    axios
      .put(`https://allinonetables.com/api/banner/${id}`, data)
      .then((res) => {
        const response = res.data;
        console.log(72, response);
        handleUpload(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <>
      <h3 className="mb-4 title">Banner</h3>
      <form
        class="row g-3 pt-3 shadow p-3 mb-5"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        <div class="col-md-7">
          <label for="inputEmail4" class="form-label txt-input">
            Main Text <span className="text-danger"></span>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Enter main text.."
            value={data.mainText}
            onChange={(e) => {
              setData({ ...data, mainText: e.target.value });
              console.log(e.target.value);
            }}
          />
        </div>

        <div class="col-md-7">
          <label for="inputEmail4" class="form-label txt-input">
            Sub Text <span className="text-danger"></span>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Enter sub text.."
            value={data.subText}
            onChange={(e) => {
              setData({ ...data, subText: e.target.value });
              console.log(e.target.value);
            }}
          />
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Upload File <span className="text-danger"></span>
          </label>
          <input
            class="form-control"
            type="file"
            id="formFile"
            accept="image/*"
            onChange={(e) => {
              setSelectedImage(e.target.files[0]);
              setFile(e.target.files[0])
              handleImageChange(e);
            }}
          />
          {selectedImage && (
            <div>
              <img
                src={selectedImage}
                alt=""
                style={{ height: "30px" }}
                className="m-1"
              />
            </div>
          )}
        </div>

        <div class="col-md-12 " style={{ marginTop: 20 }}>
          {id ? (
            <>
              <Link
                to={""}
                type=""
                class="btn btn-primary fw-bold shadow border-0 "
                style={{ backgroundColor: "#e67929", width: 100, height: 40 }}
                onClick={(e) => {
                  updateFormData(e);
                }}
              >
                Update{" "}
              </Link>
            </>
          ) : (
            <>
              <Link
                type=""
                class="btn btn-primary fw-bold shadow border-0 "
                style={{ backgroundColor: "#e67929", width: 100, height: 40 }}
                onClick={(e) => {
                  SaveData(e);
                }}
              >
                Submit
              </Link>
            </>
          )}
        </div>
      </form>
    </>
  );
}

export default Banner;
