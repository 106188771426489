import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const SubCatEdit = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [AllSuperCat, setAllSuperCat] = useState([]);
  const [AllCat, setAllCat] = useState([]);
  const [AllCatData, setAllCatData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const { id } = useParams();
  console.log(id);
  const [catData, setcatData] = useState([]);
  const [data, setData] = useState({
    name: "",
    categoryImage: "",
    parentId: "",
    parentName: "",
    superCatName: "",
    Type: "",
  });

  useEffect(() => {
    axios
      .get("https://allinonetables.com/api/category/get")
      .then((res) => {
        const response = res.data.categories;
        setAllCatData(response);
        const Allsupcat = response.filter((item) => item.Type == "Super_Cat");
        setAllSuperCat(Allsupcat);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  useEffect(() => {
    console.log(34, data);
    axios
      .get(`https://allinonetables.com/api/getcategory/get/${id}`)
      .then((res) => {
        const response = res.data;
        data.name = response[0].name;
        data.parentName = response[0].parentName;
        data.parentId = response[0].parentId;
        console.log(27, response);
        setcatData({
          name: response.name,
          parentName: response.parentName,
          parentId: response.parentId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id,refresh]);

  const SaveCAt = (e) => {
    e.preventDefault();
    console.log(61, data);
    axios
      .post("https://allinonetables.com/api/category/createTemp", data)
      .then((res) => {
        const response = res.data.categories;
        toast.success("Added Category !");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getCat = (id) => {
    console.log(54, id);
    const getCat = AllCatData.filter((item) => item.parentId == id);
    setAllCat(getCat);
  };

  return (
    <>
      <h3 className="mb-4 title ">Edit Sub Category</h3>

      <form
        class="row g-3 pt-3 shadow p-3 mt-4"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        <div class="col-md-6">
          <div class="col-md-4">
            <label for="inputState" class="form-label txt-input">
              Sub Category <span className="text-danger"></span>
            </label>
            <br />
            <select
              name=""
              id=""
              style={{
                width: "200px",
                backgroundColor: "#dce1e5",
                color: "black",
              }}
              className="btn btn-secondary border-0"
              onChange={(e) => {
                const selectedValue = e.target.value;
                setData({
                  ...data,
                  superCatName: selectedValue,
                  Type: "Sub_cat",
                });
                console.log("ok");
                getCat(
                  AllSuperCat.find((item) => item.name === selectedValue)._id
                );
              }}
            >
              <option style={{ background: "white", color: "black" }} value="">
                --Main Category--
              </option>
              {AllSuperCat.map((item) => {
                return (
                  <>
                    <option
                      style={{
                        background: "white",
                        color: "black",
                        textAlign: "left",
                      }}
                      class="dropdown-item"
                      href="#"
                      value={item.name}
                    >
                      {item.name}
                    </option>
                  </>
                );
              })}
            </select>

            <br />
            <br />

            <select
              name=""
              id=""
              style={{
                width: "200px",
                backgroundColor: "#dce1e5",
                color: "black",
              }}
              className="btn btn-secondary border-0"
              onChange={(e) => {
                const selected = e.target.value;
                const single_cat = AllCat.filter(
                  (item) => item._id == selected
                );
                console.log(200, single_cat[0]._id);
                setData({
                  ...data,
                  parentId: single_cat[0]._id,
                  parentName: single_cat[0].name,
                });
              }}
            >
              <option style={{ background: "white", color: "black" }} value="">
                {" "}
                --Category--
              </option>
              {AllCat.map((item) => {
                return (
                  <>
                    <option
                      class="dropdown-item"
                      href="#"
                      style={{
                        background: "white",
                        color: "black",
                        textAlign: "left",
                      }}
                      value={item._id}
                    >
                      {item.name}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          <br />
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Enter sub category.."
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Upload File <span className="text-danger"></span>
          </label>
          <input
            class="form-control"
            type="file"
            id="formFile"
            onChange={(e) => {
              handleImageChange(e);
            }}
          />
          {selectedImage && (
            <div>
              <img
                src={selectedImage}
                className="mx-1 mt-1"
                alt="Selected"
                style={{ maxWidth: "100%", maxHeight: "33px" }}
              />
            </div>
          )}
        </div>

        <div class="col-md-12 " style={{ marginTop: 20 }}>
          <button
            type="submit"
            class="btn btn-primary shadow border-0 p-2 fw-bold"
            style={{ backgroundColor: "#e67929", width: 100 }}
            onClick={(e) => {
              SaveCAt(e);
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default SubCatEdit;
